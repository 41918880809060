<template>
  <div class="pageContent offerReducedRefund">
    <div class="container mt3 mb3">
      <div class="columns">
        <div class="column is-7">
          <h1>
            {{ getText('or001') }}
          </h1>
          <div>
            <div>              
              <p>{{ getText('or002') }}{{data.refund_percentage}}{{ getText('or003') }}</p>
            </div>
            <div class="btnBar column">
              <b-button class="mt1 bg" type="is-warning" @click.stop="accept">
                {{ getText('accept', true) }}
              </b-button>
              <b-button class="mt1 bg" type="is-success" @click.stop="decline">
                {{ getText('decline', true) }}
              </b-button>
            </div>
          </div>
        </div>

        <div class="column">

          <ReturnItemSummary :items="data.items" />
          
        </div>
      </div>

      
    </div>
    
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import ErrorModal from "@/components/Modals/ErrorModal.vue";
import ReturnItemSummary from "@/components/ReturnItemSummary";

export default {
  name: 'OfferReducedRefund',

  components: {
    ReturnItemSummary
  },

  props: [
    'data',
    'returnReasons'
  ],

  data()
  {
    return {
      isLoading: false,
      possibleResponses: ['free_return','keep_items','customer_paid_return','contact_cs', 'disposal'],
      returnQty: 'single',
      pageText: [],
      generalText: []

    }
  },

  computed:
  {  
   
  },

  mounted()
  {
    this.pageText = this.$tools.translatePage();
    this.generalText = this.$tools.translatePage('general');
    if(this.pageText == false || this.generalText == false)
      this.openErrorMessage('yellow267-'+this.$tools.getLocaleId());
    this.getReturnQty();
  },

  methods:
  {

    getText(ref,general = false){
      let txtArray = general == false ? this.pageText : this.generalText;
      if(txtArray.length > 0){
        let t = this.$tools.renderText(txtArray,ref);
        if( t.indexOf('blue561')>-1 == true )
        {
          this.openErrorMessage(t);
        } else
        {
          return t;
        }
      }
    },

    openErrorMessage(errorCode)
    {
      this.$buefy.modal.open({
        parent: this,
        component: ErrorModal,
        hasModalCard: true,
        props: {
          errorCode: errorCode
        }
      });
    },
    
    getReturnQty()
    {
      let items = this.data.items;
      if(items.length > 1 || this.data.items[0].qtyToReturn > 1){
        this.returnQty = 'multiple';
      }
    },

    accept()
    {
      this.processResponse(true);
    },
    
    decline()
    {
      this.processResponse(false);
    },

    processResponse(offerAccepted)
    {
      this.dataForReturn = JSON.parse(JSON.stringify(this.data));
      this.isLoading = true;
      let postData = {
        "orderId": this.dataForReturn.external_id,
        "reducedRefundOffered": true,
        "reducedRefundOfferAccepted": offerAccepted,
        "items": []
      }

      for (let item of this.dataForReturn.items)
      {
        let el = {
          "id": item.id,
          "quantity": item.qtyToReturn,
          "reasonId": item.reason,
          "costPrice": item.cost_price
        }
        postData.items.push(el);
      }

      this.$api.post('route_return.php',postData)
      .then(response => {
        if (response.status == 200)
        {
          let responseData = response.data.data.data;
          if(!responseData)
          {
            if(response.data.data.message.indexOf('return address') > -1)
            {
              this.openErrorMessage('tan388-' + this.dataForReturn.address_country.toUpperCase());
            }
            else if (response.data.data.message.indexOf('No available carrier') > -1)
            {
              this.openErrorMessage('brown235b');
            }
            else
            {
              this.openErrorMessage('salmon008b');
            }
          } else
          {
            if(this.possibleResponses.includes(responseData.response_type) > -1)
            {
              this.dataForReturn.response_type = responseData.response_type;
              this.dataForReturn.label = responseData.courier_label || '';
              this.dataForReturn['courier_label_type'] = responseData.courier_label_type; //pdf or png
              this.dataForReturn['reduced_refund_offer_accepted'] = offerAccepted;
              this.$emit('done', this.dataForReturn);
            } else
            {
              this.openErrorMessage('orange384b');
            }
          }
          
          this.isLoading = false;
        }
      }).catch(() => {
        this.openErrorMessage('red493b');
        this.isLoading = false;
      });
    }

    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .b-radio + p { margin-left: 1.75em; }
  .hideThis { opacity: 0; }
  .bg.is-warning{background-color:#ffe08a!important;}
  .bg.is-success{background-color:#48c78e!important;}
</style>
